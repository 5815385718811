import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/index"
import Detail from "../layouts/detail"

import { convert2Articles } from "../utils/helper"

export default ({ data, pageContext }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  const tagArticles = data.allMarkdownRemark.nodes.filter(
    item => item.frontmatter.tag === post.frontmatter.tag
  )
  const recommendArticles = convert2Articles(tagArticles)

  return (
    <Layout isSpacerDisplay={false} title={post.frontmatter.title} isFixedNavbar={false}>
      <Detail
        post={post}
        previous={previous}
        next={next}
        recommendArticles={recommendArticles}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        tag
      }
      fields {
        slug
      }
      tableOfContents(maxDepth: 3)
    }
    allMarkdownRemark {
      nodes {
        excerpt(truncate: true, pruneLength: 40)
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          tag
        }
        fields {
          slug
        }
      }
    }
  }
`
