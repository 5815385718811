import React, { useEffect } from "react"
import { Link } from "gatsby"

import CardContainer from "../components/cardContainer"
import Toc from "../components/toc"
import styles from "./detail.module.css"
import dateIcon from "../icons/date.svg"

export default ({ post, previous, next, recommendArticles }) => {
  return (
    <div className="outerContainer">
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-12-tablet is-8-desktop is-8-widescreen is-8-fullhd has-order-1 column-main">
              <div className="card">
                <article className={styles.article}>
                  <header className={styles.header}>
                    <h1 className={styles.title}>{post.frontmatter.title}</h1>
                    <div className={styles.tagContainer}>
                      <div className={styles.dateContainer}>
                        <img className={styles.icon} alt="" src={dateIcon} />
                        <p className={styles.date}>{post.frontmatter.date}</p>
                      </div>
                      <div className={styles.analysisContainer}>
                        <span
                          id={post.fields.slug}
                          className="leancloud_visitors"
                          data-flag-title="Your Article Title"
                        >
                          <span className="post-meta-item-text">阅读量 </span>
                          <span className="leancloud-visitors-count"></span>
                        </span>
                      </div>
                    </div>
                  </header>
                  <section
                    id="articleDetail"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </article>
              </div>

              <div className="card card-transparent">
                <div className="level post-navigation is-flex-wrap is-mobile">
                  <div className="level-start">
                    {previous && (
                      <Link
                        className={styles.link}
                        to={previous.fields.slug}
                        rel="prev"
                      >
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </div>
                  <div className="level-end">
                    {next && (
                      <Link
                        className={styles.link}
                        to={next.fields.slug}
                        rel="next"
                      >
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-4-tablet is-4-desktop  is-hidden-touch is-4-widescreen is-3-fullhd has-order-2 column-right">
              <CardContainer articles={recommendArticles} title="行业资讯" />
              {post.tableOfContents && <Toc post={post} />}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
